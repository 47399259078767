import React from 'react'
import Layout from "../../../layout/Layout"

const PlanMedia = (props)=>{
    return(
    <Layout head='Plan media'>

    </Layout>
    )
}


export default PlanMedia