import React from 'react'
import Layout from '../../../layout/Layout'

const Activity = (props)=>{
    

 

    return(
        <Layout head='Activities / Name'>

        </Layout>
    )
}


export default Activity